import { clsx } from "clsx"

import type { UnlikelyMoney } from "@unlikelystudio/commerce-connector"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import serializePrice from "~/data/price/serializer"
import useLocale from "~/hooks/useLocale"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

type TotalPriceProps = PropsWithClassName<{
  totalPrice: Nullish<UnlikelyMoney>
  totalDiscount: Nullish<UnlikelyMoney>
}>

function TotalPrice({ className, totalPrice, totalDiscount }: TotalPriceProps) {
  const t = useTranslate()
  const locale = useLocale()
  const price = serializePrice(locale, totalPrice?.currencyCode, totalPrice?.amount)

  if (!price) return null

  const discountPrice = totalDiscount ? serializePrice(locale, totalDiscount?.currencyCode, totalDiscount?.amount) : 0

  return (
    <div className={clsx(css.TotalPrice, className)}>
      {totalDiscount && (
        <p className={clsx(css.totalDiscount)}>
          <span>{t("cart_total_discount")}</span>
          <span className={clsx(css.price, css.highlight)}>{`- ${discountPrice}`}</span>
        </p>
      )}
      <p className={css.total}>
        <span>{t("cart_estimed_total")}</span>
        <span className={clsx(css.price)}>{price}</span>
      </p>
    </div>
  )
}

export default TotalPrice
