import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { getDefaultVariant } from "~/lib/shopify/utils/get-default-variants"
import type { CardProps } from "~/components/ui/CartPanel/CompleteTheLookSlider/Card"
import serializeImage from "~/components/ui/Image/_data/serializer"
import serializeLink from "~/components/ui/Link/_data/serializer"
import { serializeVariants } from "~/components/ui/ProductHeader/_data/serialize-variant"

export function serializeCard(data: Nullish<UnlikelyProduct>, locale: string): CardProps | null {
  if (!data) return null

  const image = serializeImage(data.images[0], locale)
  const title = data.title
  const variants = serializeVariants(data?.variants, locale)
  const defaultVariant = getDefaultVariant(variants, data)

  if (!image || !title) return null

  return {
    id: data.id as string,
    handle: data.handle,
    image,
    title,
    price: defaultVariant?.price,
    compareAtPrice: defaultVariant?.compareAtPrice,
    vpPrice: defaultVariant?.vpPrice,
    link: serializeLink(data, locale),
  }
}

export function serializeCards(data: Nullish<UnlikelyProduct[]>, locale: string) {
  if (!data) return null

  return data.map((item) => serializeCard(item, locale)).filter(Boolean)
}
