import type { ISbStoryData } from "storyblok-js-client"

import type { Nullish } from "~/@types/generics"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import serializeImage from "~/components/ui/Image/_data/serializer"
import serializeLink from "~/components/ui/Link/_data/serializer"
import {
  reinsuranceSliderSchema,
  type ReinsuranceSliderFromSchema,
} from "~/components/ui/ProductHeader/components/ProductDetails/components/ReinsuranceSlider/_data/schema"
import { notEmptyObject } from "~/utils/not-empty-object"

export function serializeProductReinsuranceSliderItem(
  item: ReinsuranceSliderFromSchema["items"][number],
  locale: Nullish<string>
) {
  const serializedItem = {
    image: serializeImage(item.image, locale) ?? null,
    title: item.title,
    subtitle: item.subtitle,
    link: item?.link ? serializeLink(item.link, locale) : null,
  }

  return notEmptyObject(serializedItem)
}

export function serializeReinsuranceSlider(data: Nullish<ISbStoryData["content"]>, locale: Nullish<string>) {
  const content = handleZodSafeParse(reinsuranceSliderSchema, data, `serializeReinsuranceSlider`)
  const items = content?.items.map((item) => serializeProductReinsuranceSliderItem(item, locale))?.filter(Boolean) ?? []

  return { items }
}
