"use client"

import { clsx } from "clsx"

import Slider from "@unlikelystudio/react-slider"

import type { PropsWithClassName } from "~/@types/generics"
import Card, { type CardProps } from "~/components/ui/CartPanel/CompleteTheLookSlider/Card"
import { usePanel } from "~/managers/PanelManager"

import * as css from "./styles.css"

export type CompleteTheLookSliderProps = PropsWithClassName<{ items: CardProps[] }>

function CompleteTheLookSlider({ className, items }: CompleteTheLookSliderProps) {
  const { removeCurrent } = usePanel()

  if (!items?.length) return null

  return (
    <div className={clsx(className, css.CompleteTheLookSlider)}>
      <Slider containerClassName={css.sliderContainer} snap>
        {items.map((card) => (
          <Card key={card.title} className={clsx(css.card)} {...card} onClick={removeCurrent} />
        ))}
      </Slider>
    </div>
  )
}

export default CompleteTheLookSlider
