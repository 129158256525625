import { z } from "zod"

import { imageSchema } from "~/components/ui/Image/_data/schema"
import { linkSchema } from "~/components/ui/Link/_data/schema"

const reinsuranceSliderItemSchema = z.object({
  image: imageSchema,
  title: z.string().nullable(),
  subtitle: z.string().nullable(),
  link: linkSchema.optional(),
})

export const reinsuranceSliderSchema = z.object({
  items: z.array(reinsuranceSliderItemSchema),
})

export type ReinsuranceSliderFromSchema = z.infer<typeof reinsuranceSliderSchema>
