import type { ReactElement, SelectHTMLAttributes } from "react"
import { clsx } from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import Icon from "~/components/abstracts/Icon"
import type { Dictionary } from "~/providers/I18nProvider/constants"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export type SizeSelectProps = PropsWithClassName<SelectHTMLAttributes<HTMLSelectElement>> & { isSelectColor?: boolean }

function SizeSelect({ className, ...props }: SizeSelectProps) {
  const label = useGetTranslatedLabel(props.name)
  const currentValue = (props?.children as ReactElement[])?.find(
    (child) => `${child.props.value}` === `${props.value || props.defaultValue}`
  )?.props.children

  const hasMoreThanOneValue = Array.isArray(props.children) ? props.children.length > 1 : false
  const pointerEvents = hasMoreThanOneValue ? "all" : "none"
  const removeBorderClassName = props.isSelectColor ? css.removeBorder : null

  return (
    <div className={clsx(className, css.SizeSelect, removeBorderClassName)}>
      <select {...props} className={clsx(css.element, sprinkles({ pointerEvents }))} />
      <div className={clsx(css.container)}>
        <p className={clsx(css.content)}>
          <label className={clsx(css.label)} htmlFor={label}>
            {label}:{" "}
          </label>
          <span>{currentValue}</span>
        </p>
        {hasMoreThanOneValue ? (
          <Icon className={clsx(css.icon)} name={"Chevron"} direction="bottom" theme="black" />
        ) : null}
      </div>
    </div>
  )
}

export default SizeSelect

const LABELS_TRANSLATE_KEYS = {
  size: "cart_taille",
  taille: "cart_taille",
  color: "cart_couleur",
  couleur: "cart_couleur",
  qté: "cart_quantity",
} satisfies Record<string, keyof Dictionary>

function useGetTranslatedLabel(name: string | undefined) {
  const t = useTranslate()

  if (!name) return name

  const key = LABELS_TRANSLATE_KEYS?.[name.toLowerCase() as keyof typeof LABELS_TRANSLATE_KEYS]

  if (!key) return name

  return t(key)
}
