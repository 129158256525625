import { useQuery } from "@tanstack/react-query"
import { useAtom } from "jotai"

import { sortByGID } from "~/lib/shopify/hooks/useSfProductIdsCardSlidersQuery"
import { getSfProductsByIds } from "~/lib/shopify/queries/get-sf-products-by-id"
import { getGIDFromReferenceId } from "~/lib/shopify/utils/id"
import { useFetchSbStoryQuery } from "~/lib/storyblok/hooks/useFetchSbStoryQuery"
import useLocale from "~/hooks/useLocale"
import { serializeCards } from "~/components/ui/CartPanel/CompleteTheLookSlider/Card/data/serializer"
import { nostoSessionPagesAtom } from "~/components/globals/NostoSessionHandler"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

export function useCompleteTheLookSliderProductCards() {
  const t = useTranslate()
  const locale = useLocale()
  const [nostoRecommendations] = useAtom(nostoSessionPagesAtom)

  const { data } = useFetchSbStoryQuery(["globals/cart", { locale }, {}], {
    keepPreviousData: true,
    enabled: Boolean(nostoRecommendations?.forCartPage?.length),
  })

  const placement = data?.data?.story?.content?.cart_nosto_placement_id
  const recommendation = nostoRecommendations?.forCartPage?.[placement] ?? nostoRecommendations?.forCartPage?.[0]
  const ids =
    recommendation?.primary?.map((p) => getGIDFromReferenceId(Number(p.productId), "Product")).filter(Boolean) ?? []
  const title = recommendation?.resultTitle || t("cart_complete_the_look")

  return useQuery({
    queryKey: ["CompleteTheLookSliderProductCards", ids],
    queryFn: async () => {
      const { products } = await getSfProductsByIds({
        ids,
        locale,
      })

      return {
        title,
        items: serializeCards(sortByGID(ids, products), locale),
      }
    },
    enabled: Boolean(ids.length),
  })
}
