import { useQuery } from "@tanstack/react-query"

import type { GID, UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import { getSfProductsByIds } from "~/lib/shopify/queries/get-sf-products-by-id"
import useLocale from "~/hooks/useLocale"
import { serializeUnlikelyProductsToProductCardSliders } from "~/components/ui/ProductCardSlider/_data/serializer"

export function useSfProductIdsCardSlidersQuery(ids: GID[]) {
  const locale = useLocale()

  return useQuery(["SfProductIdsCardSlidersQuery", ids], async () => {
    const { products } = await getSfProductsByIds({
      ids,
      locale,
    })

    return serializeUnlikelyProductsToProductCardSliders(sortByGID(ids, products), locale)
  })
}

export function sortByGID(gids: GID[], products: UnlikelyProduct[]) {
  return gids.map((gid) => products.find((product) => product.id === gid)).filter(Boolean)
}
