"use client"

import { type ComponentProps, type MouseEvent } from "react"
import Link, { type LinkProps } from "next/link"
import { useRouter } from "next/navigation"
import { clsx } from "clsx"

import type { UnlikelyMoney } from "@unlikelystudio/commerce-connector"
import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import useLocale from "~/hooks/useLocale"
import { Image } from "~/components/ui/Image"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import Price from "~/components/ui/Price"
import { serializeSfPrice } from "~/components/ui/Price/_data/serializer"
import type { TVPPrice } from "~/components/ui/ProductHeader/_data/serialize-vp-price"
import { processVpPrice } from "~/utils/vp-prices/process-vp-prices"

import * as css from "./styles.css"

export type CardProps = PropsWithClassName<
  {
    id?: Nullish<string>
    handle?: Nullish<string>
    image: TImage
    title: string
    price: Nullish<UnlikelyMoney>
    compareAtPrice: Nullish<UnlikelyMoney>
    vpPrice: Nullish<TVPPrice>
    link: LinkProps
  } & ComponentProps<"div">
>

function Card({ className, image, title, price, compareAtPrice, vpPrice, link, onClick, ...rest }: CardProps) {
  const router = useRouter()
  const locale = useLocale()
  const { data: customer } = useGetCustomer()

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    onClick?.(e)
    router.push(link.href.toString())
  }

  const { processedPrice, processedCompareAtPrice } = processVpPrice({ price, compareAtPrice, vpPrice, customer })

  const sfPrice = serializeSfPrice(locale, processedPrice, processedCompareAtPrice)

  return (
    <div className={clsx(css.Card, className)} onClick={handleClick} {...rest}>
      <Image {...image} ratio="74_110" asPlaceholder />
      <div className={clsx(css.footer)}>
        <Link className={clsx(css.title)} {...link}>
          {title}
        </Link>
        {sfPrice && <Price {...sfPrice} />}
      </div>
    </div>
  )
}

export default Card
