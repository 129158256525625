import { useMutation, type UseMutationOptions } from "@tanstack/react-query"
import type { ErrorOption } from "react-hook-form"

import { useCustomerContext, useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import { SHOPIFY_DRAFT_ORDER_CREATE_PARAMS } from "~/lib/api/constants"
import { requestNextApiEndpoint } from "~/lib/api/utils/request-endpoint"
import { getMarket } from "~/lib/i18n/utils/get-i18n"
import useLang from "~/hooks/useLang"
import useLocale from "~/hooks/useLocale"
import useOnMutationError from "~/hooks/useOnMutationError"

import { useGetCart } from "../useGetCart"

export interface getDraftOrderUrlResponse {
  errors: { message: string }[]
  urlToRedirect: string
}

export function useGetDraftOrderUrl(
  mutationOptions?: UseMutationOptions<getDraftOrderUrlResponse | null>,
  setError?: (name?: string, error?: ErrorOption) => void
) {
  const { data: customer } = useGetCustomer()
  const { customerAccessToken } = useCustomerContext()
  const onMutationError = useOnMutationError(setError)

  const { cartPayload } = useGetCart()
  const locale = useLocale()

  return useMutation<getDraftOrderUrlResponse | null>(
    async () => {
      if (!customer?.email || !customerAccessToken || !locale || !cartPayload?.id) {
        return null
      }

      return await requestNextApiEndpoint(SHOPIFY_DRAFT_ORDER_CREATE_PARAMS, {
        accessToken: customerAccessToken,
        payload: {
          cartId: cartPayload?.id,
          locale,
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: async (data, ...rest) => {
        //! standardize error response
        const error = data?.errors?.[0]
        const errorPayload = { payload: [data?.errors?.[0]] }

        if (error) {
          return onMutationError(errorPayload, rest, mutationOptions?.onError)
        }

        mutationOptions?.onSuccess?.(data, ...rest)
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    }
  )
}
