import { z } from "zod"

import { assetMetafields } from "~/components/ui/Image/_data/serializer"

export const sbImageSchema = z.object({
  alt: z.string().optional(),
  meta_data: z
    .object({
      [assetMetafields.en_alt]: z.string().optional(),
    })
    .optional(),
  filename: z.string().url(),
  focus: z.string().optional(),
})

export const unlikelyImageSchema = z.object({
  altText: z.string().nullish(),
  url: z.string().url().nullish(),
  width: z.number().nullish(),
  height: z.number().nullish(),
})

export const imageSchema = z.union([sbImageSchema, unlikelyImageSchema])

export type SfImage = z.infer<typeof unlikelyImageSchema>
export type ImageFromSchema = z.infer<typeof imageSchema>
