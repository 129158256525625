"use client"

import { createRef } from "react"
import { clsx } from "clsx"
import { useHover, useInterval } from "usehooks-ts"

import Slider, { useSliderState } from "@unlikelystudio/react-slider"

import type { PropsWithClassName } from "~/@types/generics"
import { useFetchSbStoryQuery } from "~/lib/storyblok/hooks/useFetchSbStoryQuery"
import useLocale from "~/hooks/useLocale"
import { FillImage } from "~/components/ui/FillImage"
import { Link } from "~/components/ui/Link"
import { serializeReinsuranceSlider } from "~/components/ui/ProductHeader/components/ProductDetails/components/ReinsuranceSlider/_data/serializer"

import * as css from "./styles.css"

const IMAGE_SIZES = [{ ratio: 20 / 1440 }]

export type ReinsurranceSliderProps = PropsWithClassName<{}>

function ReinsurranceSlider({ className }: ReinsurranceSliderProps) {
  const locale = useLocale()
  const [{ slideIndex, nextSlide, setSlideIndex }, setSliderState] = useSliderState()
  const containerRef = createRef<HTMLDivElement>()
  const isHover = useHover(containerRef)
  useInterval(nextSlide, isHover ? null : 6000)

  const { data } = useFetchSbStoryQuery(["globals/reinsurance_slider", { locale }, {}], {
    keepPreviousData: true,
  })

  const serializedReinsuranceSlider = serializeReinsuranceSlider(data?.data.story.content, locale)
  if (!serializedReinsuranceSlider) return null

  return (
    <div ref={containerRef} className={clsx(className, css.ReinsurranceSlider)}>
      <Slider setSliderState={setSliderState} snap infinite>
        {serializedReinsuranceSlider.items.map((item) =>
          item?.link ? (
            <Link {...item?.link} key={item.title} className={clsx(css.item)}>
              {item.image && (
                <FillImage
                  {...item.image}
                  className={clsx(css.image)}
                  sizesFromBreakpoints={IMAGE_SIZES}
                  style={{
                    objectFit: "contain",
                  }}
                />
              )}
              <span className={clsx(css.title)}>{item.title}</span>
            </Link>
          ) : (
            <div key={item.title} className={clsx(css.item)}>
              {item.image && (
                <FillImage
                  className={clsx(css.image)}
                  objectFit={"contain"}
                  sizesFromBreakpoints={IMAGE_SIZES}
                  {...item.image}
                />
              )}
              <span className={clsx(css.title)}>{item.title}</span>
            </div>
          )
        )}
      </Slider>
      <div className={clsx(css.navigation)}>
        {serializedReinsuranceSlider.items.map((_, index) => (
          <button
            key={index}
            className={clsx(css.navigationItem({ active: index === slideIndex }))}
            aria-label={`index ${index}`}
            onClick={() => setSlideIndex(index)}
          />
        ))}
      </div>
    </div>
  )
}

export default ReinsurranceSlider
