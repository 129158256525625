"use client"

import { createRef, useEffect, useRef, useState, type MouseEventHandler, type ReactElement } from "react"
import { usePathname, useRouter } from "next/navigation"
import clsx from "clsx"

import type { UnlikelyCart, UnlikelyVariant } from "@unlikelystudio/commerce-connector"
import {
  cartDictionary,
  LineItem,
  LineItems,
  TemplatesProvider,
  useGetCustomer,
} from "@unlikelystudio/react-ecommerce-hooks"

import type { PropsWithClassName } from "~/@types/generics"
import { getProductCustomAttributesValue, PRODUCT_CUSTOM_ATTRIBUTES } from "~/lib/shopify/constants"
import { isGID } from "~/lib/shopify/utils/id"
import { useGetCart } from "~/hooks/useGetCart"
import { useGetDraftOrderUrl } from "~/hooks/useGetDraftOrderUrl"
import { useGetMultipassCheckoutUrl } from "~/hooks/useGetMultipassCheckoutUrl"
import useLocale from "~/hooks/useLocale"
import CompleteTheLookSlider from "~/components/ui/CartPanel/CompleteTheLookSlider"
import { useCompleteTheLookSliderProductCards } from "~/components/ui/CartPanel/CompleteTheLookSlider/hooks/useCompleteTheLookSliderProductCards"
import ReinsurranceSlider from "~/components/ui/CartPanel/ReinsurranceSlider"
import SizeSelect from "~/components/ui/CartPanel/SizeSelect"
import TotalPrice from "~/components/ui/CartPanel/TotalPrice"
import { Image } from "~/components/ui/Image"
import { getPath } from "~/components/ui/Link/utils/href-resolver"
import PanelHeader from "~/components/ui/Panels/PanelHeader"
import Price from "~/components/ui/Price"
import { serializeSfPrice } from "~/components/ui/Price/_data/serializer"
import { serializeVariant, serializeVariants } from "~/components/ui/ProductHeader/_data/serialize-variant"
import SquareCta from "~/components/ui/SquareCta"
import { NostoSessionHandler } from "~/components/globals/NostoSessionHandler"
import type { CartLineItemPayload, TrackingEvents } from "~/providers/GTMTrackingProvider/constants"
import { useGlobalTrackingProps } from "~/providers/GTMTrackingProvider/hooks/use-get-tracking-globals"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"
import { variantToTrackingData } from "~/providers/GTMTrackingProvider/utils/variant-to-tracking-data"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { Panel, usePanel } from "~/managers/PanelManager"
import { processVpPrice } from "~/utils/vp-prices/process-vp-prices"

import * as css from "./styles.css"

export type CartPanelProps = PropsWithClassName<{}>

const defaultTotals = { totalPrice: 0, totalDiscount: 0 }

function CartPanel({}: CartPanelProps) {
  const t = useTranslate()

  const { data: customer } = useGetCustomer()
  const locale = useLocale()
  const wrapperRef = createRef<HTMLDivElement>()
  const { cartPayload, cartItemsNumber } = useGetCart()
  const router = useRouter()
  const pathname = usePathname()
  const { removeCurrent } = usePanel()
  const { sendEvent } = useTracking()
  const isOpen = useRef(false)
  const [hasItems, setHasItems] = useState(cartItemsNumber > 0)
  const globalTrackingProps = useGlobalTrackingProps()
  const { mutate: handleRedirectToMultipassUrl, isLoading } = useGetMultipassCheckoutUrl()
  const { mutate: handleDraftOrderConfirmation, isLoading: isLoadingDraftOrder } = useGetDraftOrderUrl({
    onSuccess: (data) => {
      if (data?.urlToRedirect) handleRedirectToMultipassUrl({ url: data?.urlToRedirect })
    },
  })
  const { data: productCards } = useCompleteTheLookSliderProductCards()

  const { totalPrice, totalDiscount } =
    cartPayload?.lines?.reduce((acc, item) => {
      if (!item) return acc

      const foundVariant = serializeVariant(item.merchandise, locale)
      const { processedPrice, processedCompareAtPrice } = processVpPrice({
        price: foundVariant?.price,
        compareAtPrice: foundVariant?.compareAtPrice,
        vpPrice: foundVariant?.vpPrice,
        customer,
      })

      const priceAmount = processedPrice?.amount ?? 0
      const compareAtPriceAmount = processedCompareAtPrice?.amount ?? 0
      const { totalPrice, totalDiscount } = acc
      return {
        totalPrice: totalPrice + priceAmount * (item.quantity ?? 0),
        totalDiscount:
          totalDiscount + (compareAtPriceAmount ? (compareAtPriceAmount - priceAmount) * (item.quantity ?? 0) : 0),
      }
    }, defaultTotals) ?? defaultTotals

  useEffect(() => {
    if (!cartPayload || isOpen.current) return
    sendCartEvent("view_cart", cartPayload, cartPayload.lines)
  }, [cartPayload])

  const handleCartValidation = () => {
    cartPayload?.checkoutUrl && handleRedirectToMultipassUrl({ url: cartPayload.checkoutUrl })
  }

  const handleDraftOrderValidation = () => {
    handleDraftOrderConfirmation()
  }

  const sendCartEvent = (
    eventType: keyof Pick<TrackingEvents, "view_cart" | "remove_from_cart">,
    cart: UnlikelyCart,
    items: UnlikelyCart["lines"][0][]
  ) => {
    isOpen.current = true
    try {
      sendEvent(eventType, {
        ecommerce: {
          ...globalTrackingProps,
          value: cart?.cost?.totalAmount?.amount ?? 0,
          items: items.map((lineItem) => {
            const trackingData = getProductCustomAttributesValue(lineItem.attributes, "TRACKING_DATA")?.value ?? ""
            const parsedTrackingData = JSON.parse(trackingData) as CartLineItemPayload
            return {
              ...parsedTrackingData,
              quantity: lineItem.quantity ?? 0,
            }
          }),
        },
      })
    } catch {}
  }

  return (
    cartPayload && (
      <>
        <NostoSessionHandler type="VIEWED_PAGE" skipEvents={true} />
        <TemplatesProvider
          dictionary={cartDictionary}
          components={{
            img: ({ onClick, className, ...rest }, ref) => {
              const onClickButton = onClick as unknown as MouseEventHandler<HTMLButtonElement>

              return (
                <button className={className} onClick={onClickButton}>
                  {/*@ts-ignore*/}
                  <Image ref={ref} {...rest} height={100} ratio="74_110" asPlaceholder />
                </button>
              )
            },
            label: () => <></>,
            lineItem: (lineItemprops) => {
              return (
                <LineItem
                  {...lineItemprops}
                  priceTemplate={(props) => {
                    const serializedLineItemVariant = serializeVariant(lineItemprops.lineItem.variant, locale)
                    const { processedPrice, processedCompareAtPrice } = processVpPrice({
                      price: serializedLineItemVariant?.price,
                      compareAtPrice: serializedLineItemVariant?.compareAtPrice,
                      vpPrice: serializedLineItemVariant?.vpPrice,
                      customer,
                    })

                    const serializedPrice = serializeSfPrice(locale, processedPrice, processedCompareAtPrice)
                    return <Price {...props} {...serializedPrice} />
                  }}
                />
              )
            },
            select: (props) => {
              const children = (props.children as ReactElement[]) ?? ([] as ReactElement[])
              const isSelectLineItemQuantity = props["data-role"] === "select-line-item-quantity"
              const variants = cartPayload?.lines?.flatMap(
                (item) => item.merchandise?.product?.variants
              ) as UnlikelyVariant[]
              const serializedVariants = serializeVariants(variants, locale)

              const availableVariants =
                serializedVariants?.filter((variant) => variant?.availableForSale)?.map((variant) => variant?.id) ?? []

              const childrenVariants =
                Array.isArray(children) &&
                children
                  ?.filter((child) => {
                    return isGID(child.props.value) && variants?.some((variant) => child.props.value === variant?.id)
                  })
                  ?.map((child) => {
                    const id = child.props.value
                    const disabled = !availableVariants?.includes(id)

                    return {
                      ...(child ?? {}),
                      props: {
                        ...(child?.props ?? {}),
                        disabled,
                        ...(disabled && {
                          children: `${child?.props?.children} - ${t("out_of_stock")}`,
                        }),
                      },
                    }
                  })

              return (
                <SizeSelect
                  {...props}
                  className={clsx({ [css.quantitySelect]: isSelectLineItemQuantity })}
                  isSelectColor={props.name ? ["Color", "Couleur"].includes(props.name) : false}
                  name={isSelectLineItemQuantity ? t("cart_quantity") : props.name}
                >
                  {isSelectLineItemQuantity ? props.children : childrenVariants}
                </SizeSelect>
              )
            },
          }}
        >
          <Panel zIndex={70} clickOutsideRef={wrapperRef}>
            <div ref={wrapperRef} className={clsx(css.CartPanel)}>
              <PanelHeader
                title={
                  <>
                    {t("cart_title")} {hasItems && `(${cartItemsNumber.toString().padStart(2, "0")})`}
                  </>
                }
              />

              <div className={clsx(css.body({ hasItems }))}>
                <div className={clsx(css.bodyInner)}>
                  <LineItems
                    classNames={{
                      className: css.lineItems,
                      lineItemsListClassName: css.lineItemsList,
                      emptyCartClassName: css.emptyCart,
                    }}
                    lineItemProps={{
                      classNames: {
                        className: css.lineItem,
                        titleClassName: css.titleItem,
                        lineItemContentClassName: css.lineItemContent,
                        imageClassName: css.image,
                        priceClassName: css.price,
                        optionsClassName: css.options,
                        updateQuantityContainerClassName: css.updateQuantityContainer,
                        removeClassName: css.remove,
                      },
                      onRemoveCallbacks: {
                        onSuccess(data, variables, context) {
                          const deletedLineItemId = variables[0]
                          const deletedLineItem = context.previousCart?.cart?.lines.find(
                            (line) => line.id === deletedLineItemId
                          )

                          if (!data?.cart?.lines?.length) {
                            setHasItems(false)
                          }

                          if (deletedLineItem) {
                            sendCartEvent("remove_from_cart", data.cart, [deletedLineItem])
                          }
                        },
                      },
                      onQuantityUpdateMiddleware: ({ quantity, attributes }) => {
                        const previousTrackingData =
                          getProductCustomAttributesValue(attributes, "TRACKING_DATA")?.value ?? ""
                        const parsedTrackingData = JSON.parse(previousTrackingData) as CartLineItemPayload

                        return {
                          attributes: [
                            {
                              key: PRODUCT_CUSTOM_ATTRIBUTES.TRACKING_DATA,
                              value: JSON.stringify({
                                ...parsedTrackingData,
                                quantity,
                              }),
                            },
                          ],
                        }
                      },
                      onVariantUpdateMiddleware: ({ attributes, variant }) => {
                        const processedTrackingData = variant
                          ? variantToTrackingData(serializeVariant(variant, locale))
                          : {}

                        const previousTrackingData =
                          getProductCustomAttributesValue(attributes, "TRACKING_DATA")?.value ?? ""
                        const parsedTrackingData = JSON.parse(previousTrackingData) as CartLineItemPayload

                        return {
                          attributes: [
                            {
                              key: PRODUCT_CUSTOM_ATTRIBUTES.TRACKING_DATA,
                              value: JSON.stringify({
                                ...parsedTrackingData,
                                ...processedTrackingData,
                              }),
                            },
                          ],
                        }
                      },
                      onClick: (_, lineItem) => {
                        const path = getPath(locale, "products/:slug", { slug: lineItem?.variant?.product?.handle })
                        if (path === pathname) return removeCurrent()
                        router.push(path)
                      },
                      alwaysShowEditablesOptions: true,
                    }}
                  />
                  {hasItems && productCards?.items?.length && (
                    <>
                      <p className={css.header}>{productCards?.title}</p>
                      <CompleteTheLookSlider items={productCards?.items} />
                    </>
                  )}
                </div>
              </div>
              {!hasItems ? (
                <>
                  <div className={css.continueShopping}>
                    <SquareCta theme={"backgroundBlack"} fill onClick={removeCurrent}>
                      {t("cart_continue_shopping")}
                    </SquareCta>
                  </div>
                  {productCards?.items?.length && (
                    <div>
                      <p className={css.header}>{productCards?.title}</p>
                      <CompleteTheLookSlider items={productCards?.items} />
                    </div>
                  )}
                </>
              ) : (
                <div className={clsx(css.footer({ hasItems }))}>
                  <div className={clsx(css.footerContainer({ hasItems }))}>
                    <TotalPrice
                      className={clsx(css.totalPrice)}
                      totalPrice={
                        totalPrice && cartPayload?.cost.totalAmount
                          ? { ...cartPayload?.cost.totalAmount, amount: totalPrice }
                          : undefined
                      }
                      totalDiscount={
                        totalDiscount && cartPayload?.cost?.totalAmount
                          ? { ...cartPayload?.cost?.totalAmount, amount: totalDiscount }
                          : undefined
                      }
                    />
                    {customer && (
                      <SquareCta
                        theme={"backgroundBlack"}
                        fill
                        onClick={handleDraftOrderValidation}
                        isLoading={isLoading || isLoadingDraftOrder}
                      >
                        {t("cart_go_checkout")}
                      </SquareCta>
                    )}
                    {!customer && (
                      <SquareCta theme={"backgroundBlack"} fill onClick={handleCartValidation} isLoading={isLoading}>
                        {t("cart_go_checkout")}
                      </SquareCta>
                    )}
                    <ReinsurranceSlider className={clsx(css.reinssuranceSlider)} />
                  </div>
                </div>
              )}
            </div>
          </Panel>
        </TemplatesProvider>
      </>
    )
  )
}

export default CartPanel
